import React from 'react'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { Box } from '@material-ui/core'
interface Props {}

const steps = [
  'Validating Credit Card',
  'Creating Your LightTag Database',
  'Securing the network',
  'Sending You Next Steps',
]
const FakeLinearProgress: React.FunctionComponent = props => {
  const [value, setValue] = React.useState(1)
  React.useEffect(() => {
    const update = () => setValue(Math.pow(Math.pow(value, 19) * 100, 0.05))
    const id = setInterval(update, 150 + 2 * value)
    return () => clearInterval(id)
  }, [value])
  return (
    <>
      <LinearProgress color="primary" variant="determinate" value={value} />
    </>
  )
}
const RightPanelProgress: React.FunctionComponent<Props> = props => {
  const [step, setStep] = React.useState<number>(0)
  React.useEffect(() => {
    if (step < steps.length - 1) {
      setInterval(() => setStep(step + 1), 6000)
    }
  }, [step])
  return (
    <Box boxShadow={2} padding={2}>
      <Typography>{steps[step]}</Typography>
      <FakeLinearProgress />
    </Box>
  )
}
export default RightPanelProgress
